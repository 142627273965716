import {toast} from '@delorand/ui/src/toast';
import {client} from 'c/server/api';
import {produce} from 'immer';
import useSwr, {useSWRConfig} from 'swr';
import useSWRMutation from 'swr/mutation';
import {CartModS} from '../../../../packages/schema/dto/shared';
import {useStore} from '../store/store';

export const useCart = () => {
  const set_scs_state = useStore(state => state.set_scs_state);

  const {data, isLoading} = useSwr(['cart'], () => client.cart.get.query(), {
    // revalidateOnMount: false,
    revalidateOnReconnect: false,
    onSuccess: data => {
      set_scs_state(data?.storesCarriers ?? []);
    },
    onError: () => {
      set_scs_state([]);
    },
  });

  return {
    cartItems: data?.items ?? [],
    cart: data,
    isLoading,
  };
};

export type CartReturn = Awaited<ReturnType<typeof useCart>>;

export const useModifyCart = () => {
  const {mutate} = useSWRConfig();
  const {cartItems, cart} = useCart();

  const res = useSWRMutation(
    ['cart.modify'],
    async (_, {arg}: {arg: CartModS}) => {
      const promise = client.cart.modify.mutate(arg);
      toast.promise(promise, {
        success: res => {
          return res?.op === 'removed'
            ? `Removed from Cart`
            : res?.op === 'updated'
              ? 'Updated Item'
              : `Added To Cart`;
        },
        error: 'Error',
      });

      return promise;
    },
    {
      onSuccess: res => {
        if (!res) return;
        const placeholder = cart ?? {
          updatedAt: new Date(),
        };
        if (res.op === 'added') {
          mutate(['cart'], {
            ...placeholder,
            items: [...res.data, ...cartItems],
          });
        }
        if (res.op === 'removed') {
          mutate(
            ['cart'],
            {
              ...placeholder,
              items: cartItems.filter(x => !res.data.some(y => y.id === x.id)),
            },
            {
              revalidate: false,
              rollbackOnError: true,
            }
          );
        }
        if (res.op === 'updated') {
          const updatedItems = produce(cartItems, draft => {
            for (const draftItem of draft) {
              const matched = res.data.find(item => item.id === draftItem.id);
              if (matched) {
                draftItem.quantity = matched.quantity;
              }
            }
          });

          mutate(
            ['cart'],
            {
              ...placeholder,
              items: updatedItems,
            },
            {
              revalidate: false,
              rollbackOnError: true,
            }
          );
        }
      },
    }
  );

  return {updateCart: res.trigger, isUpdatingCart: res.isMutating};
};
