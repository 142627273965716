import type {StateCreator} from 'zustand';
import {checkoutS} from '../../../../packages/schema/dto/order';
import {SToType} from '../../../../packages/schema/dto/shared';
import {DefaultSlice} from './default-slice';
import {FeedSlice} from './feed-slice';

export const checkoutSlice: StateCreator<
  DefaultSlice & FeedSlice & CheckoutSlice,
  [
    ['zustand/immer', never],
    ['zustand/devtools', never],
    ['zustand/persist', unknown],
  ],
  [],
  CheckoutSlice
> = set => ({
  scs_state: [[], false],
  set_scs_state: scs_state =>
    set(state => {
      state.scs_state = [scs_state, true];
    }),
  setLocation: location =>
    set(state => {
      state.location = location;
    }),
});

export interface CheckoutSlice {
  location?: {
    state: string;
    city: string;
  };
  scs_state: [
    NonNullable<SToType<typeof checkoutS>['storesCarriers']>,
    boolean,
  ];
  set_scs_state: (
    value: NonNullable<SToType<typeof checkoutS>['storesCarriers']>
  ) => void;
  setLocation: (payload: {state: string; city: string}) => void;
  // resetOrder: () => void;
}
