import {StateCreator} from 'zustand';
import {CheckoutSlice} from './checkout-slice';
import {DefaultSlice} from './default-slice';

export const feedSlice: StateCreator<
  DefaultSlice & CheckoutSlice & FeedSlice,
  [
    ['zustand/immer', never],
    ['zustand/devtools', never],
    ['zustand/persist', unknown],
  ],
  [],
  FeedSlice
> = set => ({
  following: [],
  updateFollowing: storeId => {
    set(state => {
      const isFollowing = state.following.some(x => x === storeId);
      state.following = isFollowing
        ? state.following.filter(x => x !== storeId)
        : [...state.following, storeId];
    });
  },
});

export type FeedSlice = {
  following: number[];
  updateFollowing: (storeId: number) => void;
};
