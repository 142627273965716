import {AppRouter} from '@delorand/api/entry';
import {getBaseUrl} from '@delorand/utils/api';
import {createTRPCClient, httpBatchLink} from '@trpc/client';
import superjson from 'superjson';

export const client = createTRPCClient<AppRouter>({
  links: [
    httpBatchLink({
      url: `${getBaseUrl()}/api/trpc`,
      transformer: superjson,
    }),
  ],
});
