import {usePathname, useRouter, useSearchParams} from 'next/navigation';
import {
  CustomerWithAddresses,
  WalletWithPending,
} from '@delorand/db/schema/customer';
import {client} from 'c/server/api';
import useSWR from 'swr';

export const useUser: () => [
  (
    | (CustomerWithAddresses & {
        // wallet: Pick<Wallet, 'balance' | 'pendingBalances'> | null;
        wallet: WalletWithPending | null;
      })
    | null
    | undefined
  ),
  boolean,
] = () => {
  const {data, isLoading} = useSWR(
    ['userdata'],
    () => client.customer.active.query(undefined),
    {
      revalidateOnFocus: false,
      revalidateIfStale: false,
      errorRetryCount: 2,
      // revalidateOnMount: false,
    }
  );

  return [data, isLoading];
};

export const useQS = (key: string, defaultValue?: string) => {
  const searchParams = useSearchParams();
  const router = useRouter();
  const pathname = usePathname();

  const value = searchParams.get(key) ?? defaultValue ?? null;

  const setQS = (value: string) => {
    let qs = '';
    qs = qs + `${key}=${value}`;
    searchParams.forEach((v, k) => {
      if (k !== key) {
        qs = qs + `${k}=${v}`;
      }
    });

    router.replace(`${pathname}?${qs}`);
  };

  return [value, setQS] as [string | null, (key: string) => void];
};
