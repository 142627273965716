import React from 'react';
import {cn} from '@delorand/utils/cn';
import {Spinner} from './spinner';

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  asChild?: boolean;
  isLoading?: boolean;
  size?: 'base' | 'sm' | 'lg' | 'xs';
  variant?: 'white' | 'default' | 'destructive';
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      className,
      asChild = false,
      isLoading,
      disabled,
      children,
      variant = 'default',
      size,
      ...props
    },
    ref
  ) => {
    return (
      <button
        ref={ref}
        disabled={isLoading || disabled}
        {...props}
        className={cn('btn relative', className, variant, size)}
      >
        {isLoading ? (
          <>
            {/* trick to have exact button width when button is loading */}
            <span className="invisible flex gap-3">{children}</span>
            <span
              className={cn(
                'absolute inset-0 flex h-full w-full items-center justify-center'
              )}
            >
              <Spinner className="fill-white dark:fill-black" />
            </span>
          </>
        ) : (
          <>{children}</>
        )}
      </button>
    );
  }
);

export default Button;
