import axios, {AxiosInstance} from 'axios';

export const flutterwave: AxiosInstance = axios.create({
  baseURL: 'https://api.flutterwave.com/v3',
  headers: {
    Authorization: `Bearer ${process.env.FLW_SECRET_KEY}`,
  },
});
export const paystack: AxiosInstance = axios.create({
  baseURL: 'https://api.paystack.co',
  headers: {
    Authorization: `Bearer ${process.env.PAYSTACK_SECRET_KEY}`,
  },
});

export function getBaseUrl() {
  if (typeof window !== 'undefined')
    // browser should use relative path
    return '';
  if (process.env.NEXT_PUBLIC_SITE_URL)
    // reference for env site url
    return `https://${process.env.NEXT_PUBLIC_SITE_URL}`;
  if (process.env.VERCEL_URL)
    // reference for vercel.com
    return `https://${process.env.VERCEL_URL}`;
  if (process.env.RENDER_INTERNAL_HOSTNAME)
    // reference for render.com
    return `http://${process.env.RENDER_INTERNAL_HOSTNAME}:${process.env.PORT}`;
  // assume localhost
  return `http://localhost:${process.env.PORT || 3000}`;
}

export const getVendorUrl = () =>
  `https://${process.env.NEXT_PUBLIC_VENDOR_URL}`;
